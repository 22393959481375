



















































































import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from "vue-property-decorator";
@Component
export default class extends Vue {
  config = {};
  multipleSelection = [];

  @Prop({
    default: [],
    type: Array,
  })
  readonly data!: [];

  // @Prop({
  //       default: {},
  //       type: Object,
  //   })
  //   readonly pagination!: {}

  @Prop({
    default: [],
    type: Array,
  })
  readonly columns!: [];

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly loading!: boolean;
  @Prop({
    default: "",
    type: String,
  })
  readonly tree!: string;

  mounted() {
    this.config = this.$attrs;
    console.log(this.config);
  }

  getKeyValue(row: any, prop: any) {
    const arr = prop.split(".");
    if (arr.length > 1) {
      let lastKey = row;
      arr.some((element: any) => {
        if (lastKey[element]) {
          lastKey = lastKey[element];
        } else {
          return true;
        }
      });
      if (typeof lastKey === "string") {
        return lastKey;
      } else {
        return "";
      }
    } else {
      return row[prop];
    }
  }
  // indexMethod (index:any) {
  //   return index + (((this.pagination as any).currentPage || 1) - 1) * ((this.pagination as any).pageSize || 10) + 1
  // }
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
    this.$emit("emitSelection", val);
  }
  handleCurrentChange(val: any) {
    this.$emit("currentChange", val);
  }
  handleSortChange(val: any) {
    this.$emit("sortChange", val);
  }
  handleClickCell(e: any) {
    this.$emit("clickCell", e);
  }
}
